import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Image,
  Avatar,
  Button,
  Text,
  useMediaQuery,
  Link as ChakraLink,
  ButtonGroup,
} from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { EditIcon, CheckIcon } from "@chakra-ui/icons";
import Logo from "../assets/images/EYA_logo.svg";
import useAppMode from "../store/appMode/appModeState";
import { useGetProfile } from "../modules/accountAndSettings/hooks/useGetProfile";
import useAuth from "../store/auth/authState";
import useHeaderState from "../store/header/headerState";
import WelcomeModal from "./dialogs/WelcomeModal";
import EditModal from "./dialogs/EditModal";
import useI18n from "../hooks/useI18n";
import { HEADER_HEIGHT } from "../constants/layout"; // Import the header height constant

const Header: React.FC = () => {
  const [isMobileDevice] = useMediaQuery("(min-width: 768px)");
  const { isEditMode, toggleEditMode } = useAppMode();
  const { data: profile } = useGetProfile();
  const { tokens } = useAuth();
  const {
    setShowProfileButton,
    setShowEditButton,
    showNavigationButtons,
    showProfileButton,
    showEditButton,
  } = useHeaderState();
  const location = useLocation();
  const isAuthenticated = !!tokens.accessToken;
  const ml = isMobileDevice ? "8vw" : "5vw";
  const { t } = useI18n("auth");

  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  // Handle modal visibility
  const handleWelcomeModalClose = () => setIsWelcomeModalOpen(false);
  const handleEditModalClose = () => setIsEditModalOpen(false);

  // Function to initialize modal and buttons on location or token change
  useEffect(() => {
    const initializeHeaderState = () => {
      setShowProfileButton(false);
      setShowEditButton(false);

      if (["/categories", "/"].includes(location.pathname) && isAuthenticated) {
        setShowProfileButton(true);
        setShowEditButton(true);
      }

      if (!localStorage.getItem("hasSeenDemo")) {
        setIsWelcomeModalOpen(true);
        localStorage.setItem("hasSeenDemo", "true");
      }
    };

    initializeHeaderState();
  }, [
    location,
    tokens,
    setShowProfileButton,
    setShowEditButton,
    isAuthenticated,
  ]);

  // Handle Edit button click
  const handleEditButtonClick = () => {
    isAuthenticated ? toggleEditMode() : setIsEditModalOpen(true);
  };

  // Render the navigation links
  const renderNavigationLinks = () => (
    <ButtonGroup spacing={4} mr="auto" ml="auto">
      <ChakraLink as={RouterLink} to="/">
        <Button size="lg" colorScheme="teal" variant="ghost">
          {t("home")}
        </Button>
      </ChakraLink>
      <ChakraLink as={RouterLink} to="/categories">
        <Button size="lg" colorScheme="teal" variant="ghost">
          {t("categories")}
        </Button>
      </ChakraLink>
      <ChakraLink as={RouterLink} to="/contactUs">
        <Button size="lg" colorScheme="teal" variant="ghost">
          {t("contact_us")}
        </Button>
      </ChakraLink>
    </ButtonGroup>
  );

  // Render the profile section (Avatar and Name)
  const renderProfile = () => (
    <ChakraLink
      as={RouterLink}
      to="/account/info"
      _hover={{ textDecoration: "none" }}
    >
      <Flex alignItems="center" ml={4} cursor="pointer">
        <Avatar
          size="md"
          name={profile?.name || profile?.email}
          src={profile?.avatarUrl}
        />
        <Text ml={2} fontWeight="bold" size="lg">
          {profile?.name}
        </Text>
      </Flex>
    </ChakraLink>
  );

  // Render the authentication buttons
  const renderAuthButtons = () => (
    <ChakraLink as={RouterLink} to="/auth/sign-in">
      <Button size="lg" colorScheme="teal" variant="outline" mr={4}>
        {t("sign_in")}
      </Button>
    </ChakraLink>
  );

  // Render the Edit button
  const renderEditButton = () => (
    <Button
      onClick={handleEditButtonClick}
      size="lg"
      colorScheme="teal"
      variant={isEditMode ? "solid" : "outline"}
      leftIcon={
        isEditMode ? <CheckIcon boxSize="25px" /> : <EditIcon boxSize="25px" />
      }
      mr={4}
      _hover={{
        border: isEditMode ? "2px solid teal.600" : "2px solid teal.300",
        transform: "scale(1.05)",
        transition: "all 0.3s ease-in-out",
      }}
    >
      {isEditMode ? t("finish_edit_category") : t("edit_category")}
    </Button>
  );

  return (
    <>
      <Flex
        as="header"
        alignItems="center"
        justifyContent="space-between"
        fontFamily="Poppins Polly, sans-serif"
        px={ml}
        py={4} // Added some padding for better spacing
        height={HEADER_HEIGHT} // Set fixed height
        position="sticky"
        top="0"
        zIndex="1000"
      >
        {/* Logo */}
        <Box>
          <ChakraLink as={RouterLink} to="/">
            <Flex align="center" justifyContent="center">
              <Image boxSize="110px" src={Logo} alt="EYA Logo" />
            </Flex>
          </ChakraLink>
        </Box>

        {/* Navigation Links */}
        <Flex alignItems="center" justifyContent="center" flex="1">
          {showNavigationButtons && renderNavigationLinks()}
        </Flex>

        {/* User Profile and Edit Buttons */}
        <Flex alignItems="center">
          {showEditButton && renderEditButton()}
          {showProfileButton && renderProfile()}
          {!isAuthenticated && renderAuthButtons()}
        </Flex>
      </Flex>

      {/* Modals */}
      <WelcomeModal
        isOpen={isWelcomeModalOpen}
        onClose={handleWelcomeModalClose}
        isAuthenticated={isAuthenticated}
      />
      <EditModal isOpen={isEditModalOpen} onClose={handleEditModalClose} />
    </>
  );
};

export default Header;
