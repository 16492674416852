import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Box,
  Flex,
  Image,
  IconButton,
  CloseButton,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import useI18n from "../../hooks/useI18n";

interface WelcomeModalProps {
  isOpen: boolean;
  onClose: () => void;
  isAuthenticated: boolean;
}

const WelcomeModal: React.FC<WelcomeModalProps> = ({
  isOpen,
  onClose,
  isAuthenticated,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const { t } = useI18n("auth");
  const steps = [
    {
      text: t("welcome_modal.step_1"),
      image: "https://via.placeholder.com/800x400.png?text=Step+1", // Placeholder image, replace with actual image
    },
    {
      text: t("welcome_modal.step_2"),
      image: "https://via.placeholder.com/800x400.png?text=Step+2",
    },
    {
      text: t("welcome_modal.step_3"),
      image: "https://via.placeholder.com/800x400.png?text=Step+3",
    },
    {
      text: t("welcome_modal.step_4"),
      image: "https://via.placeholder.com/800x400.png?text=Step+4",
    },
    {
      text: t("welcome_modal.step_5"),
      image: "https://via.placeholder.com/800x400.png?text=Step+5",
    },
    {
      text: t("welcome_modal.step_6"),
      image: "https://via.placeholder.com/800x400.png?text=Step+6",
    },
    {
      text: t("welcome_modal.step_7"),
      image: "https://via.placeholder.com/800x400.png?text=Step+7",
    },
    {
      text: t("welcome_modal.step_8"),
      image: "https://via.placeholder.com/800x400.png?text=Step+8",
    },
    {
      text: t("welcome_modal.step_9"),
      image: "https://via.placeholder.com/800x400.png?text=Step+9",
    },
    {
      text: t("welcome_modal.step_10"),
      image: "https://via.placeholder.com/800x400.png?text=Step+10",
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent maxW="900px">
        <ModalHeader>
          {t("welcome_modal.title")}
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={onClose}
          />
        </ModalHeader>
        <ModalBody>
          <Box position="relative">
            <Carousel
              selectedItem={currentStep}
              onChange={setCurrentStep}
              showThumbs={false}
              showStatus={false}
              showIndicators={false}
              infiniteLoop
              interval={5000}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <IconButton
                    boxSize={12}
                    aria-label={label}
                    icon={<ArrowBackIcon />}
                    onClick={onClickHandler}
                    position="absolute"
                    left="25px"
                    top="45%"
                    transform="translateY(-50%)"
                    zIndex={2}
                    variant="ghost"
                    bg="whiteAlpha.700"
                  />
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <IconButton
                    boxSize={12}
                    aria-label={label}
                    icon={<ArrowForwardIcon />}
                    onClick={onClickHandler}
                    position="absolute"
                    right="25px"
                    top="45%"
                    transform="translateY(-50%)"
                    zIndex={2}
                    variant="ghost"
                    bg="whiteAlpha.700"
                  />
                )
              }
            >
              {steps.map((step, index) => (
                <Box key={index} p={4} textAlign="center">
                  <Image src={step.image} alt={`Step ${index + 1}`} mb={4} />
                  <Text fontSize="lg">{step.text}</Text>
                </Box>
              ))}
            </Carousel>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Flex w="100%" justifyContent="space-between">
            {!isAuthenticated ? (
              <>
                <Button colorScheme="teal" onClick={onClose}>
                  {t("guest")}
                </Button>
                <Flex>
                  <Button
                    colorScheme="teal"
                    variant="outline"
                    as={RouterLink}
                    to="/auth/sign-in"
                    mr={3}
                  >
                    {t("sign_in")}
                  </Button>
                  <Button colorScheme="teal" as={RouterLink} to="/auth/sign-up">
                    {t("sign_up")}
                  </Button>
                </Flex>
              </>
            ) : (
              <Button colorScheme="teal" onClick={onClose}>
                {t("continue")}
              </Button>
            )}
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WelcomeModal;
