// axios.ts
import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from "axios";
import store, { RootState } from "../store";
import { refreshToken as refreshUserToken } from "../modules/auth/services/auth.service";
import { setTokensAction, clearTokensAction } from "../store/auth/authState";

const baseURL = process.env.REACT_APP_API_URL as string;

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (token) {
      prom.resolve(token);
    } else {
      prom.reject(error);
    }
  });

  failedQueue = [];
};

const axiosInstance: AxiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const state: RootState = store.getState();
    const accessToken = state.auth.accessToken;
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config, response } = error;
    const originalRequest = config;

    if (response && response.status === 401) {
      const state: RootState = store.getState();
      const refreshToken = state.auth.refreshToken;

      if (!refreshToken) {
        store.dispatch(clearTokensAction());
        // window.location.href = "/auth/sign-in";
        return Promise.reject(error);
      }

      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const response = await refreshUserToken(refreshToken);
          const newAccessToken = response.data.accessToken;
          const newRefreshToken = response.data.refreshToken;

          store.dispatch(
            setTokensAction({
              accessToken: newAccessToken,
              refreshToken: newRefreshToken,
            }),
          );
          processQueue(null, newAccessToken);

          originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return axiosInstance(originalRequest);
        } catch (err) {
          processQueue(err, null);
          store.dispatch(clearTokensAction());
          // window.location.href = "/auth/sign-in";
          return Promise.reject(err);
        } finally {
          isRefreshing = false;
        }
      } else {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            return axiosInstance(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
export type { AxiosResponse, AxiosRequestConfig };
